
body {
  margin: 0;
  font-family: "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-button {
  background-color: #000000;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

p,
h1,
h2,
h3,
h4,
span,
img,
.awesome-iframe {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#tidio-chat-iframe,
button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:focus-visible {
  outline: none !important;
}
