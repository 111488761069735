@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./components/index.css";
@import "./pageComponents/index.css";

body {
  margin: 0;
  padding: 0;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  max-width: 100%;
  background-color: black;
}

.app-root-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  overflow-y: hidden;
}

#tidio-chat {
  @media (max-width: 1024px) {
    display: none;
  }
}

@media (max-width: 1024px) {
  .content-container {
    margin-right: 0 !important;
  }
}

.navbar {
  margin-right: 65px;
}

@media (max-width: 1024px) {
  .navbar {
    margin-right: 0 !important;
  }
}
