.page-navigation {
  @apply flex;
  transition: filter 0.5s, transform 0.5s;

  svg{
    width: 28px;
    height: 28px;
  }
}

.sidebar {
  @apply flex items-center h-full z-[9] fixed right-0 bottom-0 bg-white border-solid border-black;

  @media (max-width: 1024px) {
    width: 100%;
    height: 60px;
    bottom: 0;
  }

  @media (min-width: 1024px) {
    margin: auto;
    top: 0;
    border-left: 1px;
  }
}

.navigation {
  @apply list-none p-0 m-0 w-16;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
}

.navigation-item {
  @apply p-4 flex cursor-pointer border-b-[1px] border-t-[1px] border-solid border-[#ccc] w-full justify-center;
  transition: background-color 0.5s;

  &:active{
    background-color: rgb(209, 192, 192);
    svg {
      transform: scale(1.2);
    }
    img {
      transform: scale(1.2);
    }
  }
  
  &:hover {
    svg {
      transform: scale(1.2);
    }
    img {
      transform: scale(1.2);
    }
  }
}





.active {
  background-color: rgb(209, 192, 192);
  svg {
    transform: scale(1.2);
  }
  img {
    transform: scale(1.2);
  }
}

.rotating-element {
  animation: rotate 10s linear infinite;
}


