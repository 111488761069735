
.header {
  @apply bg-black p-1 fixed w-full z-[999] text-sm;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .header-logo {
    @apply bg-[#002733] text-white p-1 w-36 text-center h-10 leading-10 tracking-wide text-[8px];
  }

  @media (max-width: 1024px) {
    .navbar-items {
      display: none;
    }
  }
}
